.news-list {
  max-height: 550px; // Fixed height for scrolling
  overflow-y: auto; // Enable vertical scrolling when necessary
  padding-left: 0;
  padding-right: _size(section-spacing-small);
  list-style: none; // Remove default bullets
}

.outstanding {
  color: red;
  font-weight: bold;
  font-size: 1.2rem;
}

li.news-item {
  display: flex;
  align-items: flex-start;
  padding-left: _size(element-margin); // Indent for custom bullet
  margin-bottom: -0.9em;
  position: relative;

  &::before {
    content: '•'; // Custom bullet
    font-size: 1.5em;
    color: _palette(fg-bold);
    position: absolute;
    left: 0;
    line-height: 0.5;
  }

  .news-date {
    font-size: 1em;
    line-height: 1.5; // Adjust line height for tighter spacing
    color: _palette(fg);

    b {
      color: _palette(fg-bold);
      font-weight: _font(weight-heading-bold);
    }

    a {
      @extend a; // Use link styles from theme
    }
  }
}
