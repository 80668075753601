/* Skills */

.skills .title {
	text-align: center;
}

.skill-button-container {
	margin: 1.5em 0;
	text-align: center;
}

.skillbutton {
	box-shadow: none;
	outline: none
}

.skillbutton-active {
	box-shadow: inset 0 0 0 1px _palette(accent);
	color: _palette(accent) !important;
}

.skillbar {
	position:relative;
	display:block;
	margin-bottom:15px;
	width:100%;
	background:#eee;
	height:35px;
	border-radius:3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	-webkit-transition:0.4s linear;
	-moz-transition:0.4s linear;
	-ms-transition:0.4s linear;
	-o-transition:0.4s linear;
	transition:0.4s linear;
	-webkit-transition-property:width, background-color;
	-moz-transition-property:width, background-color;
	-ms-transition-property:width, background-color;
	-o-transition-property:width, background-color;
	transition-property:width, background-color;
}


.skillbar-title {
	position:absolute;
	top:0;
	left:0;
	font-weight:bold;
	font-size:13px;
	color:#fff;
	background:#6adcfa;
	-webkit-border-top-left-radius:3px;
	-webkit-border-bottom-left-radius:4px;
	-moz-border-radius-topleft:3px;
	-moz-border-radius-bottomleft:3px;
	border-top-left-radius:3px;
	border-bottom-left-radius:3px;
}

.skillbar-title span {
	display:block;
	background:rgba(0, 0, 0, 0.1);
	padding:0 20px;
	height:35px;
	line-height:35px;
	-webkit-border-top-left-radius:3px;
	-webkit-border-bottom-left-radius:3px;
	-moz-border-radius-topleft:3px;
	-moz-border-radius-bottomleft:3px;
	border-top-left-radius:3px;
	border-bottom-left-radius:3px;
}

.skillbar-bar {
	height:35px;
	width:0px;
	background:#6adcfa;
	border-radius:3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
}

.skill-bar-percent {
	position:absolute;
	right:10px;
	top:0;
	font-size:11px;
	height:35px;
	line-height:35px;
	color:#444;
	color:rgba(0, 0, 0, 0.4);
}

.skill-row-container {
	max-height: 400px; /* Adjust as needed */
	overflow-y: auto; /* Enable vertical scrolling */
	padding-right: 10px; /* Optional for better UX */
}

.experience-container {
	display: flex; /* Ensures side-by-side layout for tabs and content */
	//border: 1px solid #ddd;
	border-radius: 8px;
	//margin: 20px auto;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	font-family: 'Arial', sans-serif;
}

.tabs-container {
	width: 44%; /* Fixed width for tabs section */
	background-color: #f9f9f9;
	border-right: 1px solid #ddd;
	padding: 10px;
	box-sizing: border-box;
}

.tabs {
	display: grid;
	grid-template-columns: repeat(2, 1fr); /* Default: Two equal-width columns */
	gap: 10px; /* Adds spacing between tabs */
}

.tab {
	text-align: center;
	cursor: pointer;
	padding: 10px;
	border: 1px solid transparent;
	transition: background-color 0.3s, color 0.3s, border-color 0.3s;
	background-color: #fff; /* Default background */
	border-radius: 5px;
}

/* Media query to make tabs stack in a single column */
@media (max-width: 600px) {
	.tabs {
		grid-template-columns: 1fr; /* Single column layout for smaller screens */
	}
}

.tab h4 {
	margin: 5px 0;
	font-size: 0.7em;
	font-weight: bold;
}

.tab p {
	margin: 0;
	font-size: 0.7em;
	color: #555;
}

.tab small {
	color: #888;
	font-size: 0.6em;
}

.tab.active {
	background-color: rgba(221, 241, 222, 0.84); /* Softer green for active tab */
	color: white; /* Contrast text */
	font-weight: bold;
	border-color: #4caf50;
	transform: translateY(-10px);
	box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.tab:hover {
	background-color: rgb(178, 232, 180); /* Softer green for active tab */
	color: white;
	transform: translateY(-10px);
	box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.tabs .tab:last-child {
	grid-column: span 1; /* Last tab spans both columns if odd number of tabs */
}

.tab-content {
	width: 56%; /* Fixed width for content section */
	padding: 20px;
	background-color: #fff;
}

.tab-content h4 {
	margin: 0;
	font-size: 0.8em;
	color: #007bff;
}

.tab-content p {
	margin: 10px 0;
	font-size: 0.7em;
	color: #555;
}

.tab-content small {
	display: block;
	margin-bottom: 15px;
	color: #888;
	font-size: 0.7em;
}

.tab-content ul {
	padding-left: 20px;
}

.tab-content li {
	margin-bottom: 15px;
	line-height: 1.3;
	color: #555;
	font-size: 0.8em;
}

.education {
	margin: 20px auto;
	font-family: 'Arial', sans-serif;
	max-width: 900px;
	text-align: center;
}

.title h3 {
	font-size: 1.8em;
	margin-bottom: 20px;
}

.education-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	gap: 20px;
}

.degree-card {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 8px;
	padding: 15px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s, box-shadow 0.3s;
	text-align: center;
}

.degree-card:hover {
	transform: translateY(-10px);
	box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

.degree-logo {
	width: 60px;
	height: 60px;
	object-fit: contain;
	margin-bottom: 10px;
}

.degree-info {
	text-align: center;
}

.degree-info h4 {
	font-weight: normal;
	text-transform: none;
	letter-spacing: 0.16em;
	font-size: 0.8em;
	margin-bottom: 0.1em;
}

.degree-info .school-name {
	display: block;
	height: 2.4em; /* Ensures space for 2 lines of text */
	overflow: hidden; /* Hides overflowing text */
	text-overflow: ellipsis; /* Adds ellipsis if text is too long */
	white-space: normal; /* Allows wrapping to 2 lines */
	line-height: 1.4em; /* Adjust line spacing */
	margin-bottom: 0.5em;
}

.degree-info .location {
	text-transform: none;
	padding-top: 0.3em;
	margin-bottom: 0.1em;
	font-size: 0.8em;
	color: #555;
}

.degree-info .degree-year {
	text-transform: none;
	color: #888;
	font-size: 0.8em;
	margin-top: 0.5em;
}
